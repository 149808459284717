import { Controller } from "stimulus"
// import SmoothScroll from "smooth-scroll";

export default class extends Controller {
  static targets = ["burger", "overlay"]
  connect() {
    



  // $("span.close, .backdrop.close").on("click", function () {
  //   $(".mobile-overlay").removeClass("active");
  //   $("body").removeClass("noscroll");
  // });

  // $(".mobile-overlay").on("click", function (e) {
  //   e.stopPropagation();
  // });
  }

  open(e){
    e.preventDefault()
    this.overlayTarget.classList.add("active")
    document.body.classList.add("noscroll")
  }

  close(){
    this.overlayTarget.classList.remove("active")
    document.body.classList.remove("noscroll")
  }

}

